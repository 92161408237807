import styled from "styled-components";

const HeadlineContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  text-align: center;
  flex-direction: column;

  p {
    font-size: 3.75rem;
    text-align: start;
    line-height: 120%;
  }

  strong {
    font-size: 3.75rem;
    text-align: start;
    line-height: 120%;
  }

  .headline-content {
    padding-left: 6vw;
    z-index: 2;
  }

  @media (max-width: 1440px) {
    p {
      font-size: 2.75rem;
    }

    strong {
      font-size: 2.75rem;
    }
  }

  @media (max-width: 1025px) {
    p {
      font-size: 2rem;
    }

    strong {
      font-size: 2rem;
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;

    .headline-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 0;

      p {
        font-size: 1.75rem;
        text-align: center;
      }

      strong {
        font-size: 1.75rem;
        text-align: center;
      }
    }
  }

  @media (max-width: 440px) {
    .headline-content {
      p {
        font-size: 1.5rem;
      }

      strong {
        font-size: 1.5rem;
      }
    }
  }

  @media (max-width: 426px) {
    .headline-content {
      p {
        font-size: 1.25rem;
      }

      strong {
        font-size: 1.5rem;
      }
    }
  }

  @media (max-width: 376px) {
    .headline-content {
      p {
        font-size: 1.15rem;
      }

      strong {
        font-size: 1.25rem;
      }
    }
  }
`;

export const Headline = () => {
  return (
    <HeadlineContainer
      className="headline mt-6
      "
    >
      <div className="headline-content">
        <p className="">
          tire no mínimo <span className="text-[#D6ED16]">5</span> <br /> nos
          dados e ganhe ATÉ
        </p>
        <strong className="text-[2.15rem] text-[#D6ED16]">
          20 rodadas grátis
        </strong>
      </div>
    </HeadlineContainer>
  );
};
