import styled from "styled-components";
import { Button } from "../Button";
import { useApp } from "../../hooks/useApp";
import { Offer } from "../Offer";

const ModalContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .modal-bg {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
  }

  .modal-layout {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: calc(100% - 32px);
    background: rgba(0, 7, 13, 0.9);
    z-index: 20;
    border: 1px solid #0075cd;
    border-radius: 1rem;
    padding: 60px 18px 40px 18px;
    max-width: 672px;

    h3 {
      font-family: "Gotham-Black";
      font-size: 2rem;
      text-transform: uppercase;
      background: linear-gradient(90deg, #d6ed16, #a5b711, #717d0c);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .modal-redline {
      p {
        max-width: 80%;
        font-size: 1.5rem;
        font-family: "Gotham-Medium";
        strong {
          font-family: "Gotham-Black";
        }
      }

      @media (max-width: 768px) {
        h3 {
          font-size: 1.5rem;
        }

        p {
          font-size: 1.25rem;
        }
      }

      @media (max-width: 425px) {
        margin-bottom: 1.5rem;

        p {
          font-size: 0.875rem;
        }
      }
    }

    .offer-item {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      border: 1px solid #8bcdff;
      border-radius: 8px;
      height: 55px;
      gap: 4px;
      max-width: 500px;

      img {
        width: 24px;
        height: 27px;
      }

      p {
        font-size: 1.25rem;
        font-family: "Gotham-Medium";
      }

      strong {
        font-size: 1.25rem;
        font-family: "Gotham-Black";
      }

      span {
        font-size: 1.25rem;
        font-family: "Gotham-Medium";
      }

      @media (max-width: 768px) {
        p {
          font-size: 1rem;
        }

        strong {
          font-size: 1rem;
        }

        span {
          font-size: 1rem;
        }
      }

      @media (max-width: 425px) {
        p {
          font-size: 0.875rem;
        }

        strong {
          font-size: 0.875rem;
        }

        span {
          font-size: 0.875rem;
        }
      }

      @media (max-width: 425px) {
        p {
          font-size: 0.75rem;
        }

        strong {
          font-size: 0.75rem;
        }

        span {
          font-size: 0.75rem;
        }
      }
    }

    .offer-container {
      @media (max-width: 320px) {
        margin-bottom: 1.25rem;
      }
    }

    .modal-button {
      @media (max-width: 320px) {
        padding: 1rem;
        * {
          font-size: 0.75rem;
        }
      }
    }
  }
`;

export const Modal = () => {
  return (
    <ModalContainer className="z-20 ">
      <div className="modal-bg" />
      <div className="modal-layout text-white ">
        <div className="modal-redline flex flex-col items-center text-center mb-12">
          <h3>Boaaa!</h3>

          <p>
            Você ganhou até <strong>R$ 7.000 de bônus</strong> no primeiro
            depósito e a chance de ser sorteado a um{" "}
            <strong>iPhone 15 Pro Max.</strong>
          </p>
        </div>

        <div className="offer-container flex flex-col items-center mb-12 w-full gap-3">
          <Offer className="offer-item">
            <div className="offer-item">
              <img
                src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/6b1e1315-f1a7-46e6-0177-a7e11443c200/public"
                alt=""
              />
              <strong>20 rodadas grátis</strong>
              <span>|</span>
              <p>Deposite R$20</p>
            </div>
          </Offer>

          <Offer className="offer-item">
            <div className="offer-item">
              <img
                src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/6b1e1315-f1a7-46e6-0177-a7e11443c200/public"
                alt=""
              />
              <strong>30 rodadas grátis</strong>
              <span>|</span>
              <p>Deposite R$50</p>
            </div>
          </Offer>

          <Offer className="offer-item">
            <div className="offer-item">
              <img
                src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/6b1e1315-f1a7-46e6-0177-a7e11443c200/public"
                alt=""
              />
              <strong>60 rodadas grátis</strong>
              <span>|</span>
              <p>Deposite R$100</p>
            </div>
          </Offer>
        </div>

        <Button className="modal-button py-3 uppercase mb-7 px-10 gap-2 max-w-none">
          <Offer>
            <p>finalizar cadastro</p>
            <img
              src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/d7850022-2660-4708-ba41-90ef37ace000/public"
              alt=""
            />
          </Offer>
        </Button>

        <img
          className="max-w-[146px] mb-4"
          src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/af2db3d6-fa3c-49ce-b14e-e966183bd000/public"
        />
      </div>
    </ModalContainer>
  );
};
