import { useTimer } from "react-timer-hook";

export function Timer({ expiryTimestamp }: { expiryTimestamp: Date }) {
  const { seconds, minutes } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  return (
    <>
      {minutes}:{seconds}
    </>
  );
}
