import { ReactNode, createContext, useEffect, useRef, useState } from "react";
// @ts-ignore
import DisplayResults from "@3d-dice/dice-ui/src/displayResults";
// @ts-ignore
import DiceParser from "@3d-dice/dice-parser-interface";
// @ts-ignore
import DiceBox from "@3d-dice/dice-box";

type AppContextProps = {
  offer: string;

  rollDice: () => void;

  diceBoxRef: any;

  diceCanvasRef: any;

  modalIsOpen: boolean;
  setModalIsOpen: (_: boolean) => void;

  loading: boolean;
  setLoading: (_: boolean) => void;

  round: number;
};

export const AppContext = createContext({} as AppContextProps);

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const diceBoxRef = useRef<HTMLDivElement>(null);
  const diceCanvasRef = useRef<HTMLCanvasElement>(null);

  const [offer, setOffer] = useState("");
  const [loading, setLoading] = useState(false);
  const [dice, setDice] = useState<any>();
  const [drp, setDRP] = useState<any>();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [round, setRound] = useState(0);

  const rollDice = async () => {
    try {
      setLoading(true);
      dice.show().roll(drp.parseNotation("2d6"));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (diceBoxRef.current) {
      const Dice = new DiceBox("#dice-box", {
        id: "dice-canvas",
        assetPath: "/assets/dice-box/",
        startingHeight: 1,
        throwForce: 3,
        spinForce: 5,
        lightIntensity: 1,
        themeColor: "#ffffff",
        scale: 10,
      });

      const DRP = new DiceParser();
      const DiceResults = new DisplayResults("#dice-box");

      Dice.init().then(() => {
        const handleMouseDown = () => {
          const diceBoxCanvas = diceCanvasRef.current;
          if (
            diceBoxCanvas &&
            window.getComputedStyle(diceBoxCanvas).display !== "none"
          ) {
            Dice.hide().clear();
            DiceResults.clear();
          }
        };

        document.addEventListener("mousedown", handleMouseDown);

        return () => {
          document.removeEventListener("mousedown", handleMouseDown);
        };
      });

      Dice.onRollComplete = (results: any) => {
        setLoading(false);

        if (round === 1) {
          setModalIsOpen(true);
        }

        if (results[0].value >= 5) {
          setModalIsOpen(true);
        }

        setRound(round + 1);

        const rerolls = DRP.handleRerolls(results);

        if (rerolls.length) {
          rerolls.forEach((roll: any) => Dice.add(roll, roll.groupId));
          return rerolls;
        }
        const finalResults = DRP.parseFinalResults(results);
        DiceResults.showResults(finalResults);
      };

      setDRP(DRP);
      setDice(Dice);
    }
  }, [round]);

  useEffect(() => {
    if (window && window.offer) {
      setOffer(window.offer);
    }
  }, [window]);

  return (
    <AppContext.Provider
      value={{
        offer,
        rollDice,
        diceBoxRef,
        diceCanvasRef,
        modalIsOpen,
        setModalIsOpen,
        loading,
        setLoading,
        round,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
